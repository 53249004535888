import preval from 'preval.macro';
import 'utils/polyfill';

import { formatDate } from 'utils';
import 'antd/dist/antd.less';
import './startup';

console.log(
  '%c [%s] %s ',
  `background: ${
    process.env.NODE_ENV === 'production' ? '#008000' : '#f00'
  }; color: #fff;`,
  process.env.NODE_ENV,
  formatDate(preval`module.exports = Date.now()`, 'YYYY/MM/DD HH:mm:ss')
);