/**
 * 能够存取 对象、数字等 类型的 localStorage 和 sessionStorage
 * Created by Administrator on 2017/1/20.
 */
import * as env from 'utils/env';

let keyGetter = function (key) {
  return `${env.PROJECT_ID}_${key}`;
};
class Storage {
  constructor(storage) {
    this.storageImpl = storage;
  }
  getItem (key) {
    let result = {};

    try {
      let itemObject = JSON.parse(this.storageImpl.getItem(keyGetter(key)));
      result = itemObject.data;
    } catch (e) {
      // warning(false, 'JSON 解析错误');
    }
    return result;
  }
  setItem (key, data) {
    let itemObject = {
      data
    };
    let itemString = JSON.stringify(itemObject);
    this.storageImpl.setItem(keyGetter(key), itemString);
  }
  removeItem (key) {
    return this.storageImpl.removeItem(keyGetter(key));
  }
  clear () {
    this.storageImpl.clear();
  }
}

export const localStorage = new Storage(window.localStorage);

export const sessionStorage = new Storage(window.sessionStorage);

export default {
  localStorage,
  sessionStorage
};