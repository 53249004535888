import React from 'react';
import * as env from 'utils/env';
export default class Captcha extends React.Component {
  state = {
    clickCount: 0
  };
  update = () => {
    this.setState({ clickCount: this.state.clickCount + 1 });
  };
  render () {
    let captchaUrl = `${env.getCaptchaUrl()}?${this.state.clickCount}`;
    return (
      <img {...this.props} alt="图片验证码（点击刷新）" style={{ height: '30px', width: '120px', position: 'absolute', right: '1px', top: '1px' }} src={captchaUrl} onClick={this.update} />
    );
  }
}