function setCookie (cname, cvalue, exdays, path = '/', domain) {
  let expires;
  let d = new Date();
  if (exdays) {
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = "expires=" + d.toUTCString();
    domain && (document.cookie = cname + "=" + cvalue + "; " + expires + ";path=" + path + ';domain=' + domain);
    domain || (document.cookie = cname + "=" + cvalue + "; " + expires + ";path=" + path);
  } else {
    domain && (document.cookie = cname + "=" + cvalue + ";path=" + path + ';domain=' + domain);
    domain || (document.cookie = cname + "=" + cvalue + ";path=" + path);
  }
  // exdays && (document.cookie = cname + "=" + cvalue + "; " + expires + ";path=" + path);
  // !exdays && (document.cookie = cname + "=" + cvalue + ";path=" + path);
}

function getCookie(c_name, path) {
  path = path || '/';
  c_name = path === '/' ? c_name : c_name + path.replace('/', '_');
  let value = '';
  if (document.cookie.length > 0) {
    let arr = document.cookie.split(';');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].split('=')[0].trim() === c_name) {
        value = arr[i].split('=')[1];
      }
    }
  }
  return value;
}

export default {
    setCookie,
    getCookie
};