import qs from 'qs';
import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { location } from 'config';
import { resolveUrlQuery } from 'utils';
import { HttpCode } from 'utils/constants';
import { localStorage } from 'utils/storage';
/**
 * 用户退出
 */
@withRouter
@inject('common')
class Logout extends React.Component {
  componentDidMount () {
    if (localStorage.getItem('ticket')) {
      this.props.common.logout()
        .then((data) => {
          if (data.code == HttpCode.OK) {
            this.onLogout();
          }
        });
    } else {
      this.props.common.reset();
      this.onLogout();
    }
  }
  onLogout = () => {
    let { state, redirect_uri, display } = resolveUrlQuery(this.props.location.search);
    if (!redirect_uri)
      return;
    // 需验证 redirect_uri
    let callbackData = { state, error: '', error_message: '' };
    let seperator = redirect_uri.indexOf('#') !== -1 ? '&' : '#';
    if (display === 'popup') {
      location.href = `${redirect_uri}${seperator}${qs.stringify(callbackData)}`;
    } else if (display === 'redirect') {
      var callbackDataStr = JSON.stringify(callbackData);
      location.href = `${redirect_uri}${seperator}uamsdk_callback=${callbackDataStr}`;
    } else if (display === 'iframe') {
      location.href = `${redirect_uri}${seperator}${qs.stringify(callbackData)}`;
    }
  };
  render () {
    return <div />;
  }
}

export default Logout;