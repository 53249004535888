import React from 'react';
import { Spin } from 'antd';
import { inject } from 'mobx-react';

@inject('app')
class App extends React.Component {
  render () {
    
    let { app, children } = this.props;
    return (
      <Spin spinning={app.loadingVisible}>
        {children}
      </Spin>
    );
  }
}

export default App;