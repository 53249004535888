import { observable, action, flow } from "mobx";
import http from "utils/http";
import { localStorage } from "utils/storage";
import { HttpCode } from "utils/constants";
import cookie from "utils/cookie";

export class CommonModel {
  @observable
  webSiteInfo: any = {};
  @observable hasLogin = false;

  @observable logining = false;

  @observable userInfo = {
    userId: -1,
    lastLoginTime: -1,
  };

  @observable roleInfo = {
    roleId: -1,
    roleName: "",
    menuIds: [],
  };
  @observable menuTree = {};
  get ticket() {
    return localStorage.getItem("ticket");
  }
  set ticket(value) {
    localStorage.setItem("ticket", value);
  }
  get token() {
    return localStorage.getItem("token");
  }
  set token(value) {
    localStorage.setItem("token", value);
  }
  constructor() {
    this.initial();
    this.fetchWebInfo();
  }
  @action("initial")
  initial() {
    // 由集采后台管理系统登录并跳转到公共服务后台页面时,产生一个根域名下的key为"uam_sso_server_token"的共享token
    // 由于没有经过公共服务的登录页登录流程，这时本项目所在域名的localstorage中不会产生token信息。
    // 在后续的更新密码等操作时需要用到localstorage中的token，
    // 所以对这种情况进行处理，将根域名cookie中共享的token写入到localstorage中
    let token = cookie.getCookie("uam_sso_server_token");
    if (token) {
      this.token = token;
    }
  }
  @action("changeLoginStatus")
  changeLoginStatus(status) {
    this.hasLogin = status;
  }
  @action("saveInitialData")
  saveInitialData(data) {
    this.menuTree = data.menuTree;
    this.roleInfo = data.roleInfo;
  }

  initialData = flow(function* () {
    return yield http.request("common.initialData").then((data) => {
      if (data.code == HttpCode.OK) {
        let { userInfo, roleInfo } = data.data;
        this.saveInitialData({ userInfo, roleInfo });
      }
      return data;
    });
  });

  getToken = flow(function* () {
    return yield http
      .request("v1.login.getToken", { ticket: this.ticket })
      .then((data) => {
        if (data.code == HttpCode.OK) {
          this.token = data.data.token;
        } else {
          this.logining = false;
        }
        return data;
      })
      .catch(() => {
        this.logining = false;
      });
  });

  logout = flow(function* () {
    return yield http
      .request("v1.logout", { ticket: this.ticket })
      .then((data) => {
        this.reset();
        return data;
      });
  });

  @action("reset")
  reset() {
    this.ticket = "";
    this.token = "";
    this.changeLoginStatus(false);
  }

  updateData = flow(function* () {
    return yield http
      .request("back.common.initialData", null, false)
      .then((data) => {
        if (data.code == HttpCode.OK) {
          let { userInfo, roleInfo } = data.data;
          this.saveInitialData({ userInfo, roleInfo });

          // 不能在开头写，否则会为空（相互引用的问题）。
          // let reaction = require('reaction');
          // 初始化本地字典
          // reaction.common.localInitialData({ userStatus, roleDict, platformDict, menuDict });
        }
        return data;
      });
  });

  login = flow(function* (values) {
    this.logining = true;
    return yield http
      .request("v1.login.getTicket", values)
      .then((data) => {
        if (data.code == HttpCode.OK) {
          this.ticket = data.data.ticket;
          // localStorage.setItem('ticket', data.data.ticket);
          this.changeLoginStatus(true);
          this.logining = false;
        } else {
          this.logining = false;
        }
        return data;
      })
      .catch(() => {
        this.logining = false;
      });
  });

  loginByCaptchaCode = flow(function* (values) {
    this.logining = true;
    return yield http
      .request("v2.login.getTicket", values)
      .then((data) => {
        if (data.code === HttpCode.OK) {
          // localStorage.setItem('ticket', data.data.ticket);
          this.ticket = data.data.ticket;
          this.changeLoginStatus(true);
        } else {
          this.logining = false;
        }
        return data;
      })
      .catch(() => {
        this.logining = false;
      });
  });

  updatePassword = flow(function* (password, newPasswd) {
    return yield http.request("v1.system.updatePassword", {
      password,
      newPasswd,
    });
  });

  findPassword = flow(function* (userName, email = "", captchaCode) {
    return yield http.request("v1.system.resetPassword", {
      userName,
      email,
      kaptchaCode: captchaCode,
    });
  });

  sendSmsCaptchCode = flow(function* (phone) {
    return yield http.request("v1.sender.quickLogin", { phone });
  });
  @action
  fetchWebInfo = () => {
    //加载网站信息
    let siteUrl = "zlgx.com";
    let hsot = "https://news.zlgx.com";
    if (
      ["localhost", "uam-sso.test.zlgx.com"].includes(document.domain)
    ) {
      siteUrl = "test.zlgx.com";
      hsot = "http://news.test.zlgx.com";
    }
    let params = {
      platformCode: "common",
      platformType: "back",
      siteUrl,
    };
    fetch(`${hsot}/api/v1/cms/site/info`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((res) => {
        let { code, data } = JSON.parse(res);
        if (code === 0 && data) {
          this.webSiteInfo = data;
          const changeFavicon = (link) => {
            let el = document.createElement("link");
            el.rel = "icon";
            el.href = link;
            document.head.appendChild(el);
          };
          changeFavicon(data.logo); // 动态修改标签栏图标
          document.title = data.title;
        }
      })
      .catch((err) => console.log(err));
  };
}

export default new CommonModel();
