/**
 * Created by vincent on 2016/9/12.
 */
module.exports = {
    RoutePath: {
        INDEX: '/',
        LOGIN: '/login',
        LOGOUT: '/logout',
        FIND_PASSWORD: '/findPassword',
        UPDATE_PASSWORD: '/updatePassword',
    },
    HttpCode : {
        OK:0,
        AUTH_FAIL:1000,
        PASSWORD_ERROR:1002,
        NOT_LOGIN: 1004,
        NO_TOKEN: 1012,
        INVALID_TOKEN: 3303,
        INVALID_TICKET: 3304,
    }
};