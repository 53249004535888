import React, { ChangeEvent } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, computed } from 'mobx';
import { Row, Col, Input, Button } from 'antd';

import { CommonModel } from 'stores/CommonModel';
import { HttpCode } from 'utils/constants';

interface CaptchaCodeProps {
  value?: string;
  disabled?: boolean;
  /** 倒计时最大值 */
  countMaxValue?: number;
  common?: CommonModel;
  onBlur?(ev);
  onChange?(ev);
  /** 手机号 */
  phone: string;
  name?: string;
};

@inject('common')
@observer
export default class CaptchaCode extends React.PureComponent<CaptchaCodeProps> {
  state = {
    value: '',
    disabled: false,
  };
  constructor(props) {
    super(props);
    this.count = this.props.countMaxValue
  }
  static defaultProps = {
    // onChange: () => {},
    countMaxValue: 60,
    // onBlur: () => {},
  };
  static getDerivedStateFromProps(nextProps = {} as CaptchaCodeProps, state) {        
    const { value, disabled } = nextProps;
    return { value, disabled };
  }
  timer = null;
  @observable count = 0;
  @observable sendStatus = 0;
  @computed get disableSendHandler() {
    return this.sending || this.sended;
  };
  @computed get sending() {
    return this.sendStatus === 1;
  }
  @computed get sended() {
    return this.sendStatus === 2;
  }
  startCounter = () => {
    this.timer = setInterval(() => {
      this.count -- ;
      if (this.count === 0) {
        this.stopCounter();
      }
    }, 1000);
  }
  stopCounter = () => {
    this.sendStatus = 0;
    clearInterval(this.timer);
  }
  resetCounter() {
    this.stopCounter();
    this.count = this.props.countMaxValue;
  }
  handleSendSmsCode = () => {
    this.resetCounter();
    this.sendStatus = 1;
    this.props.common.sendSmsCaptchCode(this.props.phone)
      .then(res => {
        if (res.code === HttpCode.OK) {
          this.sendStatus = 2;
          this.startCounter();
        } else {
          this.sendStatus = 0;
        }
      });
  }
  handleCaptchaCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    this.setState({
      value,
    });
    this.props.onChange && this.props.onChange(ev);
  }
  handleOnBlur = (ev) => {
    this.props.onBlur && this.props.onBlur(ev);
  }
  componentWillUnmount() {
    this.stopCounter();
  }
  render() {
    const { value } = this.state;
    const { name, disabled } = this.props;
    return (
      <Row>
        <Col span={16}>
          <Input value={value} onBlur={this.handleOnBlur} onChange={this.handleCaptchaCodeChange} name={name} maxLength={4} placeholder="验证码" />
        </Col>
        <Col span={8} push={1}>
          <Button loading={this.sending} disabled={this.disableSendHandler || disabled} onClick={this.handleSendSmsCode}>
            {this.sending ? '发送中' : this.sended ? `${this.count}s后重发` : '发送验证码' }
          </Button>
        </Col>
      </Row>
    );
  }
}