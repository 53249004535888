import { observable, action } from 'mobx';

class AppModel {
  @observable
  loadingVisible = false
  @action('loading')
  changeLoading(isLoading) {
    this.loadingVisible = isLoading;
  }
};

export default new AppModel();