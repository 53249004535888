import React from 'react';
import ReactDOM from 'react-dom';
import { message } from 'antd';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { HashRouter as Router } from 'react-router-dom';
import warning from 'warning';
import App from 'modules/App';

import http from 'utils/http';
import * as env from 'utils/env';
import { HttpCode } from 'utils/constants';
import stores from './stores';
import routes from './routes';

configure({ enforceActions: "never" })

let root = document.getElementById('root');

http
  .url((method) => env.getRestApi(method))
  .addCallback((data) => {
    if (data.code !== HttpCode.OK) {
      if (data.code === HttpCode.NOT_LOGIN || data.code === HttpCode.INVALID_TOKEN || data.code === HttpCode.NO_TOKEN || data.code === HttpCode.INVALID_TICKET) {
        // 未登录
        // reaction.me.reset();
        stores.common.reset();
        message.error('未登录或登录状态已失效，请登录后再进行操作！');
        // history.go(-1);
      } else {
        message.error(data.msg || '系统错误，请稍后重试！');
      }
    }
  })
  .errorHook((method, params, error) => {
    message.error('系统错误，请稍后重试！');
    warning(false, `[http] method:${method} / params:${JSON.stringify(params)}`);
  })
  .loadingHook((visible) => {
    stores.app.changeLoading(visible);
  });

ReactDOM.render(
  <Provider {...stores}>
    <Router> 
      <App>
        {routes}
      </App>
    </Router>
  </Provider>,
  root
)
