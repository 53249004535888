import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { inject } from 'mobx-react';

import { HttpCode } from 'utils/constants';
import cookie from 'utils/cookie';

import commonStyles from 'style/index.module.less';
import styles from './index.less';

const FormItem = Form.Item;
/**
 * 密码修改
 */
@inject('common')
@Form.create()
class UpdatePassword extends React.Component {
  state = {
    confirmDirty: false
  };
  componentDidMount () {
    // 给sso_uam_bdfint_com_update设置一个随机的值，
    // 为了解决IE10获取不到在localStorage里存储的token的问题,
    // 调试发现设置一下localStorage就能获取到token，故加了下面这段代码
    localStorage.setItem('sso_uam_bdfint_com_update', Math.random());
  }
  back = () => {
    window.history.go(-1);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.common.updatePassword(values.password, values.newPassword)
          .then((data) => {
            if (data.code == HttpCode.OK) {
              this.props.common.reset();
              // reaction.me.reset(); // 手动清理登录状态缓存
              cookie.setCookie('ticket', '');
              localStorage.removeItem('sso_uam_bdfint_com_update'); // 清除掉临时设置的值
              message.success('密码修改成功，请重新登录！');
              setTimeout(function () {
                window.history.go(-1);
                setTimeout(function () {
                  window.location.reload();
                }, 100)
              }, 1500);
            }
          });
      }
    });
  };
  passwordValidator = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['newPasswordConfirm'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['newPasswordConfirm'], { force: true });
    }
    callback();
  };
  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('两次密码输入不同!');
    } if (value && value === form.getFieldValue('password')) {
      // 与原始密码相同
      callback('新密码不能和原密码相同!');
    } else {
      callback();
    }
  };
  render () {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    return (
      <div className={styles.form}>
        <h2 className={commonStyles.row} style={{ textAlign: 'center' }}>修改密码</h2>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem
            label="原密码"
            {...formItemLayout}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: '请输入原密码!'
                },
                {
                  validator: this.passwordValidator
                }
              ],
            })(
              <Input type="password" placeholder="原密码" onBlur={this.handleConfirmBlur} />
            )}
          </FormItem>
          <FormItem
            label="新密码"
            {...formItemLayout}>
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  required: true,
                  message: '请输入新密码!'
                },
                { type: 'string', pattern: /^[a-zA-Z0-9]{6,20}$/, message: '密码长度为6到20位' },
                { type: 'string', pattern: /[0-9]/, message: '密码必须包含数字' },
                { type: 'string', pattern: /[a-zA-Z]/, message: '密码必须包含字母' },
                {
                  validator: this.checkConfirm
                }
              ],
            })(
              <Input type="password" placeholder="新密码" />
            )}
          </FormItem>
          <FormItem
            label="确认新密码"
            {...formItemLayout}>
            {getFieldDecorator('newPasswordConfirm', {
              rules: [
                {
                  required: true,
                  message: '请再次输入新密码!'
                },
                {
                  validator: this.checkPassword
                }]
            })(
              <Input type="password" placeholder="确认新密码" onBlur={this.handleConfirmBlur} />
            )}
          </FormItem>
          <FormItem
            wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
              确定
                        </Button>
          </FormItem>
          <FormItem
            wrapperCol={{ offset: 6, span: 14 }}>
            <Button onClick={this.back} className="login-form-button" style={{ width: '100%' }}>
              返回
                        </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default UpdatePassword;