import React from 'react';
import qs from 'qs';
import { observable, computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Form, Input, Icon, Checkbox, Button, Tabs } from 'antd';
import { FormComponentProps } from 'antd/es/form'

import CaptchaCode from 'modules/CaptchaCode';

import cookie from 'utils/cookie';
import { resolveUrlQuery } from 'utils';
import { HttpCode } from 'utils/constants';
import { localStorage } from 'utils/storage';
// import { websiteName } from 'config';

import commonStyles from 'style/index.module.less';
import styles from './login.module.less';
import { CommonModel } from 'stores/CommonModel';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

const loginByPasswordKey = '[account]';
const loginByCaptchaCodeKey = '[captcha]';

declare interface LogonProps extends RouteComponentProps {
  common?: CommonModel;
  form?: FormComponentProps
};

declare interface LoginFields extends FormComponentProps {
  phone?: string;
  phoneCode?: string;
  userName?: string;
  password?: string;
};

/**
 * 用户登录
 */
@inject('common')
@observer
class Login extends React.Component<LogonProps & LoginFields> {

  @observable rememberMe = false;
  @observable rememberByPhone = false;
  @observable phone = '';
  @computed get disabledSmsCodeCaptcha() {
    return !this.phone;
  }
  @observable activeKey = loginByPasswordKey;
  @computed get isLoginByPassword() {
    return this.activeKey === loginByPasswordKey;
  }
  componentDidMount() {
    this.initialValues();
    if (this.props.common.ticket && cookie.getCookie('ticket')) {
      this.getToken();
    }
  }

  initialValues() {
    [loginByCaptchaCodeKey, loginByPasswordKey].map(type => {
      const values = this.getCacheValues(type);
      this.props.form.setFieldsValue(values);
      if (values.phone !== undefined) {
        this.phone = values.phone;
      }
      return type;
    }).forEach(type => {
      const remberState = this.getRemberState(type);
      if (type === loginByPasswordKey) {
        this.rememberMe = remberState;
      }
      if (type === loginByCaptchaCodeKey) {
        this.rememberByPhone = remberState;
      }
    });
  }

  getToken = () => {
    this.props.common.getToken()
      .then((data) => {
        if (data.code === HttpCode.OK) {
          this.onToken(data);
        }
      });
  };

  onToken = (res) => {
    let { state, redirect_uri, display } = resolveUrlQuery(this.props.location.search ?? '') as any;
    let [root = ''] = location.hostname.match(/\.[a-z]+\.(cc|com|cn)/) || [];

    if (!redirect_uri)
      return;
    // 需验证 redirect_uri
    let callbackData = { state, token: res.data.token, error: '', error_message: '' };
    let seperator = redirect_uri.indexOf('#') !== -1 ? '&' : '#';
    cookie.setCookie('uam_sso_server_token', res.data.token, 1, '/', root);
    if (display === 'popup' || display === 'iframe') {
      location.href = `${redirect_uri}${seperator}${qs.stringify(callbackData)}`;
    } else if (display === 'redirect') {
      var callbackDataStr = JSON.stringify(callbackData);
      location.href = `${redirect_uri}${seperator}uamsdk_callback=${callbackDataStr}`;
    } else if (display === 'customIframe') {
      window.parent.postMessage(JSON.stringify({
        type: 'afterLogin',
        display: 'customIframe',
        data: {
          redirect_uri: redirect_uri,
          token: res.data.token,
          response: res
        }
      }), '*');
    }
  };

  loginByPassword(values) {
    this.props.common.login(values)
      .then((data) => {
        cookie.setCookie('ticket', 'ticket_value');
        if (data.code == HttpCode.OK) {
          this.getToken();
        }
      });
  }

  loginByCaptchaCode(values) {
    this.props.common.loginByCaptchaCode(values)
      .then(data => {
        cookie.setCookie('ticket', 'ticket_value');
        if (data.code === HttpCode.OK) {
          this.getToken();
        }
      })
  }

  /**
   * 记住登录信息
   * @param {LoginType} type 
   * @param {LoginType} values 
   */
  setCacheValues(type, values) {
    localStorage.setItem(`[user]${type}`, values);
  }

  /**
   * 获取登录信息
   * @param {LoginType} type 
   */
  getCacheValues(type) {
    return localStorage.getItem(`[user]${type}`);
  }

  /**
   * 保存记住登录的选择状态
   * @param {LoginType} type 
   * @param {*} state 
   */
  setRemberState(type, state) {
    localStorage.setItem(`[user]${type}[rember]`, state || '');
  }

  /**
   * 获取记住登录状态
   * @param {LoginType} type 
   */
  getRemberState(type) {
    return Boolean(localStorage.getItem(`[user]${type}[rember]`));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isLoginByPassword) {
      this.props.form.validateFields(['userName', 'password'], (errors, values) => {
        if (errors) return;
        this.loginByPassword(values);
        this.setCacheValues(loginByPasswordKey, this.rememberMe ? values : {});
        this.setRemberState(loginByPasswordKey, this.rememberMe);
      });
    } else {
      this.props.form.validateFields(['phone', 'phoneCode'], (errors, values) => {
        if (errors) return;
        this.loginByCaptchaCode(values);
        this.setCacheValues(loginByCaptchaCodeKey, this.rememberByPhone ? { phone: values.phone } : {});
        this.setRemberState(loginByCaptchaCodeKey, this.rememberByPhone);
      });
    }
  };

  remberChangeHandlers = (type) => {
    if (type === loginByPasswordKey) {
      return e => {
        this.rememberMe = e.target.checked;
      }
    }

    return (e) => {
      this.rememberByPhone = e.target.checked;
    }
  }

  handleChangeLoginType = (activeKey) => {
    this.activeKey = activeKey;
  }

  handlePhoneNumberChange = () => {
    setTimeout(() => {
      this.props.form.validateFields(['phone'], (error, values) => {
        this.phone = error ? '' : values.phone;
      });
    }, 0);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { common } = this.props;
    let { common: { webSiteInfo } } = this.props;
    webSiteInfo = toJS(webSiteInfo)
    const headerTitle = webSiteInfo.init && webSiteInfo.init.headerTitle
    return (
      <div style={{ overflow: 'auto' }}>
        <div className={styles.form}>
          <h2 className={commonStyles.row} style={{ textAlign: 'center', marginTop: 20, marginBottom: 10 }}>{headerTitle}</h2>
          {/* <p className={styles.info}>{websiteName} 钢铁行业工业互联网先锋者，为工业4.0助力</p> */}
          <Form onSubmit={this.handleSubmit}>
            <Tabs activeKey={this.activeKey} onChange={this.handleChangeLoginType}>
              <TabPane tab="账户密码登录" key={loginByPasswordKey}>
                <FormItem>
                  {getFieldDecorator('userName', {
                    rules: [
                      { required: true, message: '请输入账户名!' }
                    ],
                  })(
                    <Input name="userName" prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="账户" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: '请输入密码!' },
                      { type: 'string', pattern: /^[a-zA-Z0-9]{6,20}$/, message: '密码长度为6到20位' },
                      // {type: 'string', pattern: /[0-9]/, message: '密码必须包含数字'},
                      // {type: 'string', pattern: /[a-zA-Z]/, message: '密码必须包含字母'},
                    ],
                  })(
                    <Input name="password" prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="密码" />
                  )}
                </FormItem>
                <FormItem>
                  <Checkbox checked={this.rememberMe} onChange={this.remberChangeHandlers(loginByPasswordKey)}>自动登录</Checkbox>
                </FormItem>
              </TabPane>
              <TabPane tab="手机验证码登录" key={loginByCaptchaCodeKey}>
                <FormItem>
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: true, message: '请输入手机号!' },
                      { pattern: /^1\d{10}$/, message: '手机号格式不正确' }
                    ],
                  })(
                    <Input onChange={this.handlePhoneNumberChange} onBlur={this.handlePhoneNumberChange} name="phone" maxLength={11} prefix={<Icon type="phone" style={{ fontSize: 13 }} />} placeholder="手机号" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('phoneCode', {
                    rules: [{ required: true, message: '请输入短信验证码' }],
                  })(<CaptchaCode disabled={this.disabledSmsCodeCaptcha} phone={this.phone} />)}
                </FormItem>
                <FormItem>
                  <Checkbox checked={this.rememberByPhone} onChange={this.remberChangeHandlers(loginByCaptchaCodeKey)}>自动登录</Checkbox>
                </FormItem>
              </TabPane>
            </Tabs>
            <FormItem>
              <Button type="primary" htmlType="submit" className={styles.loginButton} loading={common.logining}>
                登录
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create<LoginFields>()(withRouter(Login));