import React from 'react';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Form, Input, Button, Row, Col, message, AutoComplete } from 'antd';
import { HttpCode, RoutePath } from 'utils/constants';
import Captcha from 'modules/Captcha';
import styles from './index.less';
import commonStyles from 'style/index.module.less';
const FormItem = Form.Item;

@inject('common')
@Form.create()
class Login extends React.Component {
  state = {};
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { userName, email, captchaCode } = values;
        // email 统一规范为小写
        this.props.common.findPassword(userName, email && email.toLowerCase(), captchaCode)
          .then((data) => {
            if (data.code == HttpCode.OK) {
              message.success('密码重置成功，请查收系统邮件或者手机短信获取新密码。');
              window.location = `${window.location.protocol}//${window.location.host.replace(/http[s]?:\/\//, '')}/#/login?${window.location.href.split('?')[1]}`
            }
            this.refs.captcha.update(); // 刷新验证码
          });
      }
    });
  };
  handleAutoCompleteChange = (value) => {
    let index = value.indexOf('@');
    let emailName = index == -1 ? value : value.substring(0, index);

    if (emailName.trim() != '') {
      this.setState({
        options: [`${emailName}@bdfint.com`, `${emailName}@zlgx.com`]
      });
    } else {
      this.setState({
        options: null
      });
    }
  };
  render () {
    let { common } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    return (
      <div className={styles.form}>
        <h2 className={commonStyles.row} style={{ textAlign: 'center' }}>找回密码</h2>
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="用户名/工号" {...formItemLayout}>
            {getFieldDecorator('userName', {
              rules: [
                // {type: 'string', required: true, pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,30}$/, message: '用户名/工号为4到30位字母与数字的组合!'}
                { type: 'string', required: true, pattern: /^[A-Za-z0-9]{4,30}$/, message: '用户名为4-30位字母、数字或者二者组合' }
              ],
            })(
              <Input placeholder="用户名/工号" />
            )}
          </FormItem>
          <FormItem label="邮箱" {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [{ type: 'string', required: false }],
              onChange: this.handleAutoCompleteChange,
            })(
              <AutoComplete
                dataSource={this.state.options}
                placeholder="邮箱"
              />
            )}
          </FormItem>
          <FormItem label="验证码" {...formItemLayout}>
            {getFieldDecorator('captchaCode', {
              rules: [{ required: true, message: '请输入验证码!' },
              { type: 'string', pattern: /^[0-9a-zA-Z]{5}$/, message: '验证码长度为5位数字或字母!' }],
            })(
              <Input placeholder="验证码" />
            )}
            <Captcha ref="captcha" />
          </FormItem>
          <FormItem>
            <Col span={14} offset="6">
              <Row>
                <Col span={11}>
                  <Button size="large" type="primary" htmlType="submit" className={styles.resetButton}>
                    立即找回
                                    </Button>
                </Col>
                <Col offset={2} span={11}>
                  <Link className={styles.loginButton} to={common.hasLogin ? `/${RoutePath.INDEX}` : `/${RoutePath.LOGIN}?${window.location.href.split('?')[1]}`}>
                    <Button size="large" className={styles.resetButton}>
                      {common.hasLogin ? '返回首页' : '返回登录'}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Login;