import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import { RoutePath } from 'utils/constants';

import Login from 'modules/Login';
import Logout from 'modules/Logout';
import FindPassword from 'modules/FindPassword';
import UpdatePassword from 'modules/Validate/UpdatePassword';

export default (
  <Switch>
    <Route path={RoutePath.LOGIN} component={Login} />
    <Redirect from="/" to={RoutePath.LOGIN} exact />
    <Route path={RoutePath.LOGOUT} component={Logout} />
    <Route path={RoutePath.FIND_PASSWORD} component={FindPassword} />
    <Route path={RoutePath.UPDATE_PASSWORD} component={UpdatePassword} />
  </Switch>
);