import qs from 'qs';

/**
 * 将url上面的查询字符串解析成对象的形式
 * @param url 带有查询字符串的url
 */
export function resolveUrlQuery(
  url = '',
) {
  const search = url.slice(url.indexOf('?') + 1);
  return qs.parse(search);
  // const reg = /([^?=&]+)=([^?=&]+)/gi;
  // const result = {};
  // let match = reg.exec(url);

  // while (match) {
  //   const value = match[2];
  //   result[match[1]] = value;
  //   match = reg.exec(url);
  // }
  // return result;
}

/**
 * 修复数字前面的0
 * @param value
 */
export const fixZero = (value: number) => {
  return value < 10 ? `0${value}` : value;
}

/**
 * 简易版日期格式化显示方法
 * @param date
 * @param formatStr
 */
export function formatDate(dateTime: Date | string | number, formatStr: string = 'YYYY-MM-DD') {
  if (!dateTime) {
    return null;
  }
  var tools = {
    YYYY(date) {
      return date.getFullYear();
    },
    MM(date) {
      return fixZero(date.getMonth() + 1);
    },
    DD(date) {
      return fixZero(date.getDate());
    },
    HH(date) {
      return fixZero(date.getHours());
    },
    hh(date){
      const hours = date.getHours();
      return fixZero(hours < 12 ? hours : hours - 12);
    },
    mm(date) {
      return fixZero(date.getMinutes());
    },
    SS(date) {
      return fixZero(date.getSeconds());
    },
    ss(date) {
      return fixZero(date.getSeconds());
    }
  };
  const date = dateTime instanceof Date ? dateTime : new Date(dateTime);

  const reg = formatStr.match(/(YYYY)|(MM)|(DD)|(HH|hh)|(mm)|((ss)|(SS))/g);
  return reg.reduce((prev, current) => {
    const formatFn = tools[current];
    return formatFn ? prev.replace(current, formatFn(date)) : prev;
  }, formatStr);
}