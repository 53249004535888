import { location } from 'config';

export const ip = location.hostname === 'localhost' ? `${location.hostname}:${location.port}` : location.hostname;

export const getIp = () => ip;

export const getRestApi = (method) => `${location.protocol}//${ip}/api/${method.split('.').join('/')}`;

export const getCaptchaUrl = () => `${location.protocol}//${ip}/api/kaptcha.jpg`;

export const DEBUG = process.env.NODE_ENV !== 'production';

export const RELEASE = process.env.NODE_ENV === 'production';

export const PROJECT_ID = 'sso_uam_bdfint_com';
