// import fetch from 'isomorphic-fetch';
// import qs from 'qs';
import { localStorage } from 'utils/storage';
import reqwest from 'reqwest';

function noop (...args): any { };

let http = {
	$urlGetter: noop,
	$tokenGetter: noop,
	$tokenIsValid: noop,
	$authHook: noop,
	$errorHook: noop,
	$loadingHook: noop,
	$callbacks: [],
	url (urlGetter) {
		http.$urlGetter = urlGetter;
		return http;
	},
	addCallback (callback) {
		http.$callbacks.push(callback);
		return http;
	},


	token (tokenGetter) {
		http.$tokenGetter = tokenGetter;
		return http;
	},
	tokenIsValid (tokenIsValid) {
		http.$tokenIsValid = tokenIsValid;
		return http;
	},
	authHook (authHook) {
		http.$authHook = authHook;
		return http;
	},
	errorHook (errorHook) {
		http.$errorHook = errorHook;
		return http;
	},
	loadingHook (loadingHook) {
		http.$loadingHook = loadingHook;
		return http;
	},
	request<T = any>(method, params = {}, showLoading = true): RetureType<T> {
		showLoading && http.$loadingHook(true);
		let url = http.$urlGetter(method);
		let o = {
			url,
			type: 'json',
			method: 'post',
			contentType: 'application/json',
			crossOrigin: true,
			data: JSON.stringify({
				params,
				token: localStorage.getItem('token')
			})
		};

		return reqwest(o)
			.catch((error) => {
				http.$errorHook(method, params, error);// 错误处理
			})
			// .then((data, error)=>{
			//     if(!http.$tokenIsValid(data)){
			//         http.$authHook(data);
			//     }
			//     return data;
			// })
			.then((data) => {
				let error = true;
				for (var i = 0; i < http.$callbacks.length; i++) {
					let callback = http.$callbacks[i];
					if (callback(data)) {
						error = false;
						break;
					}
				}
				return data;
			})
			.always(() => {
				showLoading && http.$loadingHook(false);
			});
	}
};

export default http;